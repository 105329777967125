
.newLogin{
    height: 100%;
     overflow-y: auto; 
     overflow-x: hidden;
    
   
     justify-content: flex-end !important;
     align-items: baseline !important;
     padding: 0 !important;
     background-color:rgba(0,0,0,0) !important;
     z-index: 9999;
}
.newLogin .content{
    
    width: 30%;
    height: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: rgba(0, 0, 0, .85);
    margin-left: 35%;
    margin-right: 35%;
   
}
.rc-imageselect-payload
{
    transform: scale(0.77); 
    -webkit-transform: scale(0.77); 
    transform-origin: 0 0; 
    -webkit-transform-origin: 0 0;
}

@media screen and (max-width: 900px) {
    .newLogin .content{
        width: 100%;
        margin-left: 0;
        margin-right:0;


    }
}


.newLogin .content .popContainer{
      background-color: white;
    width: 100%;
    height: 90%;
    min-width: 100% !important;
    border-radius: 16px 16px 0 0;
    margin: 0;
    overflow: hidden;
} 
.newLogin .content .popContainer .titleContainers {
    position: relative;
    width: 100%;
    background-color: white;
    padding: 16px 0 10px;   
    border-bottom: 1px solid #cacaca; 
    display: flex;
    justify-content: center;
    align-items: center;
}
.newLogin .content .popContainer .titleContainers .title ,.newLogin .content .popContainer .inputsContainer .inputsContainer_header{
    position: relative;
    height: 25px;
    text-align: start;
    /* margin: 0 16px; */
    width: auto;
    font-family: 'poppins-bold','poppins-bold-ar';
    font-size: 18px;
    color: rgba(74, 74, 74, 1);
}
.newLogin .iconTimes {
    position: absolute ;
    cursor: pointer;
    z-index: 1;
    right: 16px;
    display: flex;
}
.newLogin .content .popContainer .inputsContainer .title {
    position: relative;
    text-align: start;
    width: auto;
    font-family: 'poppins-medium','poppins-medium-ar';
    font-size: 14px;
    color: rgba(74, 74, 74, 1);
    margin-top: 16px;
}

.newLogin .content .popContainer .inputsContainer .wrongNumber {
    position: relative;
    /* text-align: start; */
    width: auto;
    font-family: 'poppins-medium','poppins-medium-ar';
    font-size: 14px;
    color: rgba(74, 74, 74, 1);
    margin-top: 16px;
}
.newLogin .content .popContainer .inputsContainer .wrongNumber span{
    position: relative;
    text-align: start;
    width: auto;
    font-family: 'poppins-bold','poppins-bold-ar';
    font-size: 14px;
    color: var(--themeColor);
}

.newLogin .content .popContainer .inputsContainer .resend {
    position: relative;
    font-family: 'poppins-bold','poppins-bold-ar';
    font-size: 14px;
    color: rgba(184, 184, 184, 1);
    margin-top: 106px;
}
.newLogin .content .popContainer .firstView{
    padding: 0 24px;

}
.newLogin .content .popContainer .firstView .inputsContainer .ui.input.input input{
    /* border: none;
    border-bottom: 1px solid rgba(223, 223, 223, 1) !important; */
    /* border-radius: 0; */
    padding-left: 8px;
    font-family: 'poppins-bold','poppins-bold-ar';
    text-align: justify !important;
/* font-size: 14px; */
    color: rgba(74, 74, 74, 1)!important;
}
.newLogin .content .popContainer .verification .inputsContainer .ui.input.input input{

    font-family: 'poppins-bold','poppins-bold-ar';
    text-align: center;
    font-size: 18px;
    color: rgba(74, 74, 74, 1)!important;
    letter-spacing: 6px;
    padding: 16px ;
    background-color: rgba(247, 247, 247, 1);


}
.newLogin .content .popContainer .inputsContainer .ui.left.labeled.input.input input{
    /* border: none;
    border-bottom: 1px solid rgba(223, 223, 223, 1) !important; */
    /* border-radius: 0;
    padding-left: 8px;
    margin-left: 6px; */
    /* padding: 18px; */
    background-color: rgba(247, 247, 247, 1);


}
.newLogin .content .popContainer .inputsContainer .ui.input.input .ui.search.dropdown.label{
    /* border: none;
    border-bottom: 1px solid rgba(223, 223, 223, 1); */
    /* border-radius: 0; */
    padding: 18px;
    background-color: rgba(247, 247, 247, 1) !important;

}
.newLogin .content .popContainer .inputsContainer .ui.input.input .ui.search.dropdown.label input{
    border: none !important;
    
}
.newLogin .content .popContainer .inputsContainer .ui.input.input .ui.search.dropdown.label .text{
    color: rgba(74, 74, 74, 1)!important;
    
}
.newLogin .content .popContainer .inputsContainer .ui.input.input .ui.search.dropdown.label i{
    color: rgba(74, 74, 74, 1)!important;
    
}

/* .popContainer .actionBtnContainer{
    display: flex;
    justify-content: flex-end;
}
.popContainer .actionBtnContainer .actionBtn{
    background-color: white;
    padding: 8px 24px;
    color: var(--themeColor);;
    border: 1px solid var(--themeColor);
    cursor: pointer;
    margin: 16px;
    border-radius: 8px;
}
.popContainer .actionBtnContainer .actionBtn:focus{
    background: var(--themeColor);
    color: white;
}
.popContainer .actionBtnContainer .actionBtn:hover{
    background: var(--themeColor);
    color: white;
} */
.newLogin .content .popContainer .loginBtncontainer{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 24px;
    height:  58px;
    background: white;
    margin-top: 85px;
}
.newLogin .content .popContainer .loginBtncontainer .loginBtn{
    position: relative;
    width: 100%;
    height:  58px;
    display: grid;
    align-items: center;
    font-family: 'poppins-bold','poppins-bold-ar';
    font-size: 18px;
    background-color: var(--themeColor);
    border-radius: 6px;
    color: white;
    box-shadow: 0px 0px 9px 0px rgba(158, 158, 158, 0.5);
    cursor: pointer;
    padding: 10px;
    margin: 0;
    
}


.newLogin .content .popContainer .noAccount span{
    font-family: 'poppins-regular','poppins-regular-ar';
    font-size: 14px;
    color: rgba(52, 152, 219, 1);
    text-align: center;    
}

.newLogin .content .verification{
    padding: 16px 24px;
}
.newLogin .content .verification .inputsContainer{
    /* padding: 0 24px 8px !important; */
    text-align: center;
    font-size: 18px;
}
.newLogin .content .verification .inputsContainer .loginBtn{
    margin: 0 !important;
}