.popContainer{
    background-color: white;
    width: 30vw;
    min-width: 400px;
    border-radius: 16px;
    margin:8px;
} 

.verification{
    padding: 16px 0;
}
/* .verification .inputsContainer{
    padding: 0 24px 8px !important;
} */
.verification .inputsContainer .loginBtn{
    margin: 0 !important;
}
.dimmerClass{
    height: 100%;
     overflow-y: auto; 
     overflow-x: hidden;
}
.dimmerClass .content{
    max-height: 85%;
    /* top: 0px; */
   
    /* padding: 60px; */
    /* padding-bottom: 1000px; */
}
.popContainer .titleContainer{
    display: flex;
    justify-content: center;

}
.popContainer .inputsContainer{
    text-transform: capitalize;
    /* padding: 0 16px 16px; */
}
.popContainer .inputsContainer .socialAccounts{
    width: 100%;
    padding: 16px !important;
    margin: 0 0 16px 0;
}
.popContainer .useEmail{
    font-size: 12px;
    color: var(--themeColor);
    padding: 0 16px;
}
.popContainer .smoker{
    display: flex;
    padding: 0 16px 16px;
    justify-content: space-between;
}
.popContainer .smoker .btn{
    color: var(--themeColor);
    border: 1px solid var(--themeColor);
    background: white;
    width: 45%;
    padding: 14px;
    cursor: pointer;
    border-radius: 4px;
    transition : all 500ms;
}
.popContainer .smoker .btn:hover{
    color: white;
    background: var(--themeColor);

}
.popContainer .smoker .btnActive{
    color: white;
    border: 1px solid var(--themeColor);
    background: var(--themeColor);
    width: 45%;
    padding: 14px;
    cursor: pointer;
    box-shadow: 0px 0px 6px #D4D2D2;
    border-radius: 4px;
    transition : all 500ms;

}
.popContainer .error{
    padding: 0 16px 8px;
    color: red;
    font-size: 10px;
    max-width: 400px;
}
.popContainer .information{
    padding: 0 16px;
    color: var(--secondaryColor);;
    font-size: 10px;
    max-width: 400px;
    text-align: justify;
}
.popContainer .inputsContainer .title{
    color: black;
    /* text-align: justify; */
    font-weight: 500;
}
.popContainer .inputsContainer .detail{
    color: black;
    text-align: justify;
    font-size: 10px
}

.popContainer .inputsContainer .input{
    width: 100%;
    padding: 14px 0;
    font-size: 16px;
    
}
.popContainer .inputsContainer .ui.input>input{
    color: var(--themeColor) !important;
    border-color: #a8a8a8 !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.popContainer .inputsContainer .ui.input>input:focus,.popContainer .ui.active.label{
    border-color: var(--themeColor) !important;
}
.popContainer .inputsContainer .resend{
    padding: 12px ;
    color: var(--secondaryColor);
    border-radius: 16px;
    font-weight: 800;
    cursor: pointer;
    background-color: white;
    width: 100%;
}
.popContainer .inputsContainer .loginBtn{
    padding: 12px ;
    margin: 16px 0 0;
    border: 1px solid var(--themeColor);
    color: var(--themeColor);
    border-radius: 8px;
    /* font-weight: 800; */
    cursor: pointer;
    background-color: white;
    width: 100%;
    font-family: 'poppins-regular','poppins-light-ar';
}
.popContainer .inputsContainer .forgotPassword{
    color: var(--secondaryColor);
    cursor: pointer;
    font-size: 12px;
}
.popContainer .inputsContainer .loginBtn:hover{
    background-color: var(--themeColor);
    color:white;
}
.popContainer .inputsContainer .loginBtn:focus{
    background-color: var(--themeColor);
    color:white;
}
.popContainer .inputsContainer .areaInput{
    background: var(--themeColor);
    color: white;
    width: 15%;
    justify-content: center;
}
.popContainer .socialsContainer{
    display: flex;
    justify-content: space-around;
    padding: 16px;
}
.popContainer .socialsContainer .loginBtnfb{
    background: rgba(38, 114, 203, 1);
    width: 40%;
    padding: 8px;
    font-size: 12px;
    cursor: pointer;
}
.popContainer .socialsContainer .loginBtngoogle{
    background: rgba(252, 56, 80, 1);
    width: 40%;
    padding: 8px;
    font-size: 12px;
    cursor: pointer;
}
.popContainer .noAccount{
    padding: 0 0 24px;
    color: black;
}
.popContainer .successTitle{
    font-size: 18px;
    padding: 24px;
    color: var(--themeColor);
    text-align: justify;
    border-bottom: 1px solid rgba(238, 238, 238, 1);
    font-weight: 800;
}
.popContainer .sucessContent{
    color: rgba(74, 74, 74, 1);
    text-align: justify;
    padding: 16px;
    border-bottom: 1px solid rgba(238, 238, 238, 1);
}
.popContainer .sucessContent .fieldsContainer{
    display: flex;
    justify-content: center;
}
.popContainer .sucessContent .fieldsContainer .inputs{ 
    padding: 12px 0 0;
    font-size: 16px;
    border-bottom: 1px solid lightgray;
}
.popContainer .sucessContent .fieldsContainer .inputs.ui.input>input{ 
    text-align: center !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    padding: 4px;
}
.popContainer .sucessContent .redDetails{
    color: var(--secondaryColor);
    font-size: 14px;
    padding: 4px 0;
    line-height: 1.4;
}
.popContainer .sucessContent .summarysection{
    padding: 8px;
}
.popContainer .sucessContent .summarysection .summaryheader{
    font-size: 14px;
    font-weight: 500;
    color: rgba(184, 184, 184, 1);
}
.popContainer .sucessContent .summarysection .summarycontent{
    font-size: 16px;
    font-weight: 500;
    color: rgba(74, 74, 74, 1);
    padding: 8px 0;
    max-width: 400px;
}
.popContainer .actionBtnContainer{
    display: flex;
    justify-content: flex-end;
}
.popContainer .actionBtnContainer .actionBtn{
    background-color: white;
    padding: 8px 24px;
    color: var(--themeColor);;
    border: 1px solid var(--themeColor);
    cursor: pointer;
    margin: 16px;
    border-radius: 8px;
}
.popContainer .actionBtnContainer .actionBtn:focus{
    background: var(--themeColor);
    color: white;
}
.popContainer .actionBtnContainer .actionBtn:hover{
    background: var(--themeColor);
    color: white;
}
.ui.input>input{
    /* text-align: justify !important; */
    font-size: 16px;
}
.popContainer .ui.labeled.input>.label{
    border: 1px solid #a8a8a8;
    border-right: 0px;
}
@media screen and (max-width: 600px) {
    .popContainer{
        min-width: 95vw;
        width: 95vw;
        margin:0
    }
}
