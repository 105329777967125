.orderStatusClass {
    position: relative;
    background-color:white;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 35%; */
}
@media screen and (max-width: 900px) {
    .orderStatusClass {
        width: 100% !important;
    }
}
.orderStatusClass .orderStatusClassContainer {
    position: relative;
    height: auto;
    /* margin: 17px; */
    width: 80%;
}


.orderStatusClass .orderStatusClassContainer .titleContainer {
    position: relative;
    height: auto;
    width: 50%;
    margin: 17px 8px;
}
.orderStatusClass .orderStatusClassContainer .titleContainer .text{
    position: relative;
    font-family: 'poppins-bold','poppins-bold-ar';
    font-size: 24px;
    color: rgba(11, 23, 33, 1);
    letter-spacing: -0.03em;
    line-height: 22px;
}
.orderStatusClass .orderStatusClassContainer .titleContainer .underline{
    position: relative;
    background-color: var(--themeColor);
    height: 5px;
    border-radius: 2px;
    margin-top: 8px;
}

.orderStatusClass .orderStatusClassContainer .statusContainer {
    position: relative;
    height: auto;
    margin: 18px 0 75px;
}
.orderStatusClass .orderStatusClassContainer .statusContainer .stepsContainer{
    position: relative;
    height: auto;
}
.orderStatusClass .orderStatusClassContainer .statusContainer .stepsContainer .line{
    position: absolute;
    height: 66%;
    width: 3px;
    background-color: rgba(239, 239, 239, 1);
    margin-left: 10px;
    margin-right: 13px;
    margin-top: 66px;
}

.orderStatusClass .orderStatusClassContainer .statusContainer .stepsContainer  .titleIconContainer{
    position: relative;
    height: auto;
    display: flex;
    padding-top: 48px;
    justify-content: flex-start;
}

.orderStatusClass .orderStatusClassContainer .statusContainer .stepsContainer .titleIconContainer .icon{
    position: relative;
    width: 23px;
    font-size: 24px;
    color:var(--themeColor);
    background-color: white;

}
.orderStatusClass .orderStatusClassContainer .statusContainer .stepsContainer .titleIconContainer .textContainer{
    position: relative;
    height: auto;
    margin-left: 11px;

}
.orderStatusClass .orderStatusClassContainer .statusContainer .stepsContainer .titleIconContainer .textContainer .upperText{
    position: relative;
    height: auto;
    font-family: 'poppins-bold','poppins-bold-ar';
    font-size: 18px;
    color: rgba(11, 23, 33, 1);
    padding: 0 8px;

}
.orderStatusClass .orderStatusClassContainer .statusContainer .stepsContainer .titleIconContainer .textContainer .lowerTextContainer{
    position: relative;
    height: auto;
    display: flex;
    align-items: center;
    margin-top: 4px;
    padding: 0 2px;
}
.orderStatusClass .orderStatusClassContainer .statusContainer .stepsContainer .titleIconContainer .textContainer .lowerTextContainer .icon{
    position: relative;
    height: 14px;
    width: 14px;
    margin: 4px;
}
.orderStatusClass .orderStatusClassContainer .statusContainer .stepsContainer .titleIconContainer .textContainer .lowerTextContainer .time{
    position: relative;
    height: auto;
    font-family: 'poppins-regular','poppins-regular-ar';
    font-size: 14px;
    color: #828282;
    direction: ltr;
}
.orderStatusClass .orderStatusClassContainer .confirmDelivery{
    position: relative;
    width: 100%;
    background: var(--themeColor);
    border-radius: 6px;
    box-shadow: 0px 0px 9px 0px rgba(158, 158, 158, 0.5);
    text-align: center;
    color: white;
    padding: 16px;
    font-family: 'poppins-bold','poppins-bold-ar';
    font-size: 14px;
    height: 60px;
    transition: all 100ms;
}
.orderStatusClass .orderStatusClassContainer .confirmDelivery:hover{
    transform: scale(0.95);
}

.orderStatusClass .orderStatusClassContainer .backtomenu{
    position: relative;
    margin-top: 35px;
    width: 100%;
    text-align: center;
    font-family: 'poppins-regular','poppins-regular-ar';
    font-size: 18px;
    color: rgba(11, 23, 33, 1);
}