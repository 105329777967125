.profileSection {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;
    display: flex;
    height: 100vh;
    position: sticky;
    top:0;
}
/* .profileSection .rightSide {
    width: 65%;
    background-image: linear-gradient(25deg, rgba(0, 0, 0, 0.97), rgba(0, 0, 0, 0.64) 54.62%, rgba(0, 0, 0, 0.65)),url(./img/IMG_4391.jpeg);
    background-size: cover;
} */
.profileSection .leftSide {
    width: 100%;
    height: 100%;

}
.profile{
font-family: 'poppins-regular','poppins-regular-ar'; 

}
.profile .profileHeader{
    display: flex;
    padding: 12px;
    background-color: rgba(247, 247, 247, 1);
    /* border-bottom: 1px dashed; */
    box-shadow: 0 3px 3px #e4e2e2;
    -webkit-box-shadow: 0 3px 3px #e4e2e2;

}
.profile .profileHeader img{
    width: 60px;
    height: 60px;
    background: black;
    border: 1px solid ;
    border-radius: 50%;
    margin: 0 8px;
    padding: 0px;
    object-fit: cover;
}
.profile .profileHeader .placeholder{
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3em;
}
.profile .profileHeader .userInfo{
    padding: 12px 0;
}
.profile .profileHeader .userInfo .name{
    color:rgba(74, 74, 74, 1);
    font-size: 22px;
    font-weight: 500;
    text-transform: capitalize;
    
}
.profile .profileHeader .userInfo .area{
    color:rgba(74, 74, 74, 1);
    font-size: 16px;
}
.profile .listHeader{
    font-weight: 600;
    font-size: 16px;
    color:rgba(74, 74, 74, 1);
    background-color:rgba(74, 74, 74, 0.174);
    padding: 12px;
    border-bottom: 1px solid lightgray;

}
.profile .listItem{
    font-size: 14px;
    color:rgba(74, 74, 74, 1);
    background-color:white;
    padding: 12px;
    border-bottom: 1px solid lightgray;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}
.profile .listItem img{
    transform: rotate(180deg);
    height: 100%;
    object-fit: contain;
    width: 24px;
    background: black;
}
.profile .historyContainer{
    height: calc( 100vh - 127px);
    background-color: rgba(247, 247, 247, 1);
    overflow: auto;

}
.profile .historyContainer .historyItem{
    background-color: white;
    margin: 24px 0;
    padding: 8px;

}
.profile .historyContainer .historyItem .info{
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: rgb(125, 124, 124)

}

.profile .historyContainer .historyItem .details{
    font-size: 16px;
    padding: 16px 0 20px;
    color: rgba(74, 74, 74, 1);
    font-weight: 500;

}
.profile .historyContainer .historyItem .actionBtn{
    border: 1px solid;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
    background-color: rgba(250, 246, 237, 1);
    box-shadow: 1px 1px 2px 0px rgba(155, 152, 146, 1);
    color: var(--themeColor);
    margin-bottom: 8px;
    cursor: pointer;
    

}
@media screen and (max-width: 900px) {
    .profileSection .leftSide {
        width: 100%;
    }
    .profileSection .rightSide {
        display: none;
    }
}

