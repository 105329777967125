
.categouriesList{
    display: flex;
    flex-direction: column;
}
.categouriesList .categouryItem{
    /* width: 100%; */
    padding: 16px;
    min-height: 230px;
    /* height: 30vh; */
    margin: 16px 16px 8px;
    border-radius: 8px;
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.categouriesList .categouryItem .categouryTitle{
    position: absolute;
    bottom: 16px;
    color: white;
    font-size: 18px;
    font-family:'poppins-bold','poppins-bold-ar' ;

}