.menuSection {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;
    /* width: 100%; */
    
    -ms-overflow-style: none;
}

/* Hide scrollbar for IE and Edge */

.menuSection .searchBar {
    margin: 0 8px;
    /* padding: 0 12px; */
    width: 100%;
    text-align: justify;
    height: 40px;
    border: 1px solid var(--themeColor);
    border-radius: 4px;
    color: var(--secondaryColor);
    display: flex;
    align-items: center;
    animation:example 500ms;
    -webkit-animation: example 500ms;
}
@keyframes example {
    from {width: 0;}
    to {width: 100%;}
  }

  .discountContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: fixed; */
    /* top: 60px; */
    z-index: 999;
    /* height: 72px; */
    width: 100%;
    padding: 0 16px;
    background-color: white;
}
.discountContainer .discountContainer2{
    display: flex;
    height: auto;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 999;
    background-color: rgba(255, 119, 116, 0.2);
    padding: 8px 16px;
    border-radius: 20px;
    width: 100%;
}

 .discountContainer .discountContainer2 .text{
    height: auto;
    display:flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    font-family: 'poppins-medium','poppins-medium-ar';
    font-size: 12px;
    color: rgba(255, 119, 116, 1);
}
 .discountContainer .discountContainer2 .text .notificationicon{
    height: auto;
    position: relative;
    margin: 0 9px;
}
 .discountContainer .discountContainer2 .text span{
    font-family: 'poppins-bold','poppins-bold-ar';
    font-size: 12px;
    color: rgba(255, 119, 116, 1);
}
 .discountContainer .discountContainer2 .righticon{
    height: auto;
    position: relative;   
}
.menuSection  .searchIcon{
    background: white;
    color: var(--themeColor);
    margin: 0px 8px;
    font-size: 16px;
    /* padding: 10px; */
    width: 34px;
    height: 34px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 4px;
    box-shadow: 0px 0px 4px 0px rgba(180, 180, 180, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
.menuSection  .tagsContainer {
    display: flex;
    padding: 12px 0;
    /* height: 55px; */
    align-items: center;
    box-shadow: 0 3px 3px #e4e2e2;
    -webkit-box-shadow: 0 3px 3px #e4e2e2;
    position: relative;
    /* top: 60px; */
    /* top: 124px; */
    z-index: 999;
    width: 100%;
    background-color: white;
    position: sticky;
    position: -webkit-sticky;
    top:0;
}
.menuSection  .tagsView{
    display: flex;
    align-items: center;
    text-transform: capitalize;
    overflow: scroll;
    /* width: 100px;     */
    /* left: 0 ; */
    /* overflow-y:hidden; */
}

::-webkit-scrollbar {
    display: none;
}

.menuSection  .tagsContainer .tag {
    padding: 8px 16px;
    border: 1px solid var(--themeColor);
    margin: 0 4px;
    font-family: 'poppins-medium','poppins-medium-ar';
    font-size: 14px;
    border-radius: 6px;
    color: var(--themeColor);
    background-color: white;
    white-space: nowrap;
    /* transform: translateX(-50%); */
    /* display: inline; */
    transition: all 500ms;
    -webkit-transition:all 500ms;
}

.menuSection  .tagsContainer .selected ,.menuSection  .tagsContainer .active,.menuSection  .tagsContainer .tag:hover{
    border: 1px solid var(--themeColor);
    margin: 0 4px;
    border-radius: 6px;
    background-color: var(--themeColor);
    color: white;
    cursor: pointer;
}


.menuSection .rightSide {
    width: 65%;
    background-size: cover;
    position: fixed;
    top: 0;
    right: 0;
    /* background-image: linear-gradient(25deg, rgba(0, 0, 0, 0.97), rgba(0, 0, 0, 0.64) 54.62%, rgba(0, 0, 0, 0.65)), url(../../home/img/Header_image.jpg); */
    height: 100vh;
}
.menuSection .rightSide .sideImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
}
.menuSection .listContainer {
    /* overflow: auto; */
    /* height: calc(100% - 171px); */
    /* height: calc(100% - 115px); */
    /* min-height: 100vh; */
    position: relative;
    /* padding-top: 16px; */
    /* top: 186px; */
}

.menuSection .listContainer .title {
    font-size: 20px;
    font-family: 'poppins-bold','poppins-bold-ar';
    font-weight: 800;
    color: rgba(74, 74, 74, 1);
    padding: 20px 16px 8px 16px;
    cursor: default;
    text-transform: capitalize;
}
.menuSection .listContainer .categoryDetails{
    font-size: 12px;
    font-family: 'poppins-medium','poppins-light-ar';
    color: var(--secondaryColor);
    padding: 4px 16px 0;
    cursor: default;
}

.menuSection .listContainer .activeOrderContainer {
    position: sticky;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 20px;
    z-index: 999;
    width: 100%;
    left: 0;
}
.menuSection .listContainer .hidden {
    display: none;
}
.menuSection .listContainer .activeOrderContainer .activeOrder {
    position: relative;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    z-index: 999;
    padding: 22px 32px;
    background-color: rgba(255, 119, 116, 1);
    border-radius: 20px;
    box-shadow: 0px 0px 4px 0px rgba(99, 99, 99, 0.5);
}
.menuSection .listContainer .activeOrderContainer .activeOrder .icon{
    position: relative;
    color: white;
    font-size: 20px;
}
.menuSection .listContainer .activeOrderContainer .activeOrder .text{
    position: relative;
    font-family: 'poppins-light','poppins-light-ar';
    font-size: 18px;
    font-weight: 300;
    color: rgba(255, 255, 255, 1);
    margin: 0 16px;
}
.menuSection .listContainer .activeOrderContainer .activeOrder .text span{
    font-family: 'poppins-bold','poppins-bold-ar'; 
}
.menuSection .listContainer .list {
    font-size: 18px;
    color: rgba(74, 74, 74, 1);
    margin: 0px 16px;
}

.menuSection .listContainer .activeOrderContainer .CheckOut{
    position: relative;
    width: 80%;
    background-color: var(--themeColor);
    border-radius: 6px;
    box-shadow: 0px 0px 9px 0px rgba(158, 158, 158, 0.5);
    cursor: pointer;
    color: white;
    padding: 16px;
    font-family: 'poppins-bold','poppins-bold-ar'; 
    font-size: 14px;
    height: 60px;
    transition: all 100ms;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}
.menuSection .listContainer .activeOrderContainer .CheckOut:hover{
   transform: scale(0.95);
    
}
.menuSection .listContainer .activeOrderContainer .CheckOut .addtobasket{
    position: relative;
    font-family: 'poppins-light','poppins-light-ar'; 
    font-size: 18px;
    font-weight: 300;
    color: rgba(255, 255, 255, 1);
    text-align: center;
}
.menuSection .listContainer .activeOrderContainer .CheckOut .totalprice{
    position: relative;
    font-family: 'poppins-bold','poppins-bold-ar'; 
    font-size: 14px;
    margin-top: 3px;
    color: rgba(255, 255, 255, 1);
    text-align: center;
}

.menuSection .listContainer .list .listItem {
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    padding: 16px 0px;
    border-bottom: 1px solid #cccccc40;
    min-height: 140px;

}

.menuSection .listContainer .list .listItem .imageView{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    background-color: transparent;
    /* -webkit-box-shadow: 0 0 6px 0px rgba(0,0,0,.2);
    -moz-box-shadow: 0 0 6px 0px rgba(0,0,0,.2);
    box-shadow: 0 0 6px 0px rgba(0,0,0,.2); */
}
.menuSection .listContainer .list .listItem .itemInfo {
    width: 65%;
    /* display: flex;
    flex-direction: column;
    flex-flow: wrap; */
    /* padding: 0 18px 0 0; */
}

.menuSection .listContainer .list .listItem  .itemTitle {
    font-family: 'poppins-medium','poppins-medium-ar';
    font-size: 16px;
    cursor: default;
    /* width: 90%; */
}

.menuSection .listContainer .list .listItem  .itemTitle .deal {
    padding: 0px 6px;
    background-color: var(--secondaryColor);
    color: white;
    font-weight: 600;
    border-radius: 16px;
    font-size: 14px;
    display: inline-table;
    margin: 0 4px;
    line-height: 16px;
}
.menuSection .listContainer .list .listItem  .itemTitle:first-child {
    text-transform: capitalize;
}

.menuSection .listContainer .list .listItem  .itemDetails {
    font-family: 'poppins-regular','poppins-medium-ar'; 
    font-size: 13px;
    line-height: 18px;
    color: #a8a8a8;
    cursor: default;
    padding: 8px 0 0;
    display: block;
    display: -webkit-box;
    -moz-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp:2;
    -webkit-box-orient: vertical ;

    /* width: 90%; */
    /* font-weight: 100; */
}
.menuSection .listContainer .list .listItem  .itemPrice {
    font-family: 'poppins-medium','poppins-bold-ar'; 
    font-size: 13px;
    padding: 8px 0 0;
    color: #4a4a4a;
}


.menuSection .listContainer .list .listItem .btnHolder {
    width: 80px;
    padding-top: 8px;
}
.menuSection .listContainer .separator{
    height: 5px;
    width: 100%;
    background-color: var(--themeColor);
    opacity: 0.05;
    margin: 16px 0 0;
}

.menuSection  .ui.input>input {
    border: none !important;
}

.menuSection .loginContainer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 60px;
    z-index: 100;
}

.menuSection .loginContainer .login {
    /* width: 15%; */
    padding: 6px;
    color: var(--themeColor);
    margin: 0 16px;
    border: 1px solid var(--themeColor);
    text-align: center;
    cursor: pointer;
    transition: all 500ms;
    -webkit-transition:all 500ms;
    text-transform: capitalize;
    z-index: 100;

}

.menuSection .loginContainer .login:hover {
    color: white;
    background-color: var(--themeColor);
}

.menuSection .cartSection {
    color: white;
    width: 40%;
    margin: 0 80px;
    height: calc(100% - 60px);
    /* height: 80%; */
    overflow: auto
}
/* .menuSection .cartSection {
    color: white;
    width: 40%;
    margin: 0 80px;
    height: 80%;
    overflow: auto
} */
.menuSection .cartSection .empty{
    text-align: center;
    padding: 100px 0;
}
.menuSection .cartSection .empty .title{
    font-size: 24px;
    font-weight: 600;
    border: none;
}
.menuSection .cartSection .empty .details{
    font-size: 14px;
    border: none;
}

.menuSection .cartSection .title {
    font-size: 22px;
    font-weight: 500;
    padding: 8px 0;
    border-bottom: 1px dashed;
}


.menuSection .cartSection .cartList .cartItem {
    display: flex;
    justify-content: space-between;
    padding: 16px 0 0;
    font-size: 16px;
    width: 99%;
    line-height: 30px;
    text-transform: capitalize;
}
.menuSection .cartSection .cartList .cartItem .removeBtn{
    color: var(--themeColor) !important;
    background-color: transparent !important;
    /* width: 25%; */
    display: flex;
    justify-content: center;
    box-shadow: none;
}
.menuSection .cartSection .cartList .cartItem .btnHolder {
    display: flex;
}

.menuSection .cartSection .totalItems {
    display: flex;
    padding: 16px 0 8px;
    font-size: 16px;
    justify-content: space-between;
    font-weight: 800;
    border-top: 1px dashed;
    margin: 8px 0 0;
}

.menuSection .cartSection .deliveryCharge {
    display: flex;
    font-size: 14px;
    justify-content: space-between;
}

.menuSection .cartSection .coupon {
    margin: 16px 0;
}
.menuSection .cartSection .coupon .error{
    color: red;
    font-size: 11px;
    font-weight: 600;
    padding: 8px 0;
}

.menuSection .cartSection .coupon .ApplyCoupon {
    background-color: var(--themeColor);
    color: white;
    width: 35%;
}

.menuSection .cartSection .ui.checkbox label, .ui.checkbox+label {
    color: rgb(255, 255, 255) !important;
}

.menuSection .cartSection .ui.checkbox input:checked~.box:after, .ui.checkbox input:checked~label:after {
    color: var(--secondaryColor) !important;
}

.ui.checkbox .box:before, .ui.checkbox label:before {
    background: transparent !important;
    border-color: var(--themeColor) !important;
}

.menuSection .cartSection .placeOrderBtn {
    color: var(--themeColor);
    border: 1px solid var(--themeColor);
    border-radius: 8px;
    text-align: center;
    padding: 8px;
    margin: 24px 0;
    cursor: pointer;
    background-color: transparent;
    transition: all 500ms;
    width: 100%;
    -webkit-transition:all 500ms;
}

.menuSection .cartSection .placeOrderBtn:hover {
    background-color: var(--themeColor);
    color: white;
}

.menuSection .ui.input>input{
    text-align: justify;
}
.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking{
    display: none;
}
.menuSection .reorder{
    
    background-color: var(--themeColorOpacity05);
    padding: 10px;
}
.menuSection .reorder .title{
    font-size: 18px;
    font-weight: bold;
    color: rgba(74, 74, 74, 1);
    font-family: 'poppins-bold','poppins-bold-ar';
    padding: 8px 4px;
}
.menuSection .reorder .card{
    min-width: 80%;
    background-color: #fff;
    border-radius: 8px;
    margin: 4px;
    box-shadow: 0 0 6px #6c6c6c16;
}
.menuSection .reorder .card .cardTitle{
    font-weight: 500;
    color: rgba(74, 74, 74, 1);
    padding: 12px;
}
.menuSection .reorder .card span{
    font-weight: 700;
    font-family: 'poppins-semiBold','poppins-bold-ar';
}
.menuSection .reorder .card .price{
    color: var(--themeColor);
    font-size: 14px;
    font-weight: 700;
    font-family: 'poppins-semiBold','poppins-bold-ar';

}
.menuSection .reorder .card .reorderBtn{
    color: var(--themeColor);
    background-color: var(--themeColorOpacity30);
    padding: 4px 12px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    font-family: 'poppins-semiBold','poppins-bold-ar';

}
@media screen and (max-width: 900px) {
    .menuSection  {
        width: 100%;
    }
    .menuSection  .header .cart {
        display: flex;
    }
    .menuSection .rightSide {
        display: none;
    }

    .menuSection  .discountContainer {
        width: 100%;
    }
    .menuSection .listContainer .activeOrderContainer {
        width: 100vw;
    }
}

