.offerDetails {
    justify-content: flex-end !important;
    align-items: baseline !important;
    padding: 0 !important;
    /* background-color:rgba(0,0,0,0) !important; */
    width: 30% !important;
    right: 0 !important;
    margin-left: 35%;
    margin-right: 35%;
}

.offerDetails .content {
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: flex-end;
    /* background-color: rgba(0, 0, 0, .85); */
}

.offerDetails .content .popContainer {
    background-color: transparent;
    /* background-image: linear-gradient(to bottom, transparent, #fff, #fff); */
    width: 100%;
    height: auto;
    min-width: 100% !important;
    border-radius: 16px 16px 0 0;
    animation: fromBottomContainer 300ms ease-in;
    -moz-animation: fromBottomContainer 300ms ease-in;
    -webkit-animation: fromBottomContainer 300ms ease-in;
    color: rgba(74, 74, 74, 1);
    /* padding: 24px; */
    margin: 0;
    overflow: auto;
    max-height: 99vh;
    padding: 24px 16px;
}

.offerDetails .content .popContainer .infoContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid #F4F4F4;
    padding: 16px 0;

}

.offerDetails .content .popContainer .infoContainer .title {
    font-size: 16px;
    font-family: 'poppins-semiBold', 'poppins-bold-ar';
    text-align: start;
    line-height: 25px;
    color: #343434
}

.offerDetails .content .popContainer .infoContainer .desc {
    font-size: 14px;
    font-family: 'poppins-medium', 'poppins-medium-ar';
    text-align: start;
    line-height: 21px;
    color: #A5A5A5;
}

.offerDetails .content .popContainer .infoContainer .code {
    font-size: 18px;
    font-family: 'poppins-bold', 'poppins-bold-ar';
    text-align: start;
    line-height: 21px;
    color: var(--themeColor);

}



.offerDetails .content .popContainer .buttonContainer {
    margin-top: 47px;
    margin-bottom: 37px;
    position: relative;
    width: 100%;
    background-color: var(--themeColor);
    border-radius: 6px;
    box-shadow: 0px 0px 9px 0px rgba(158, 158, 158, 0.5);
    cursor: pointer;
    color: white;
    padding: 16px;
    font-family: 'poppins-bold', 'poppins-bold-ar';
    font-size: 14px;
    height: 60px;
    transition: all 100ms;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.offerDetails .content .popContainer .buttonContainer .text {
    font-size: 18px;
    font-family: 'poppins-semiBold', 'poppins-bold-ar';
    text-align: center;
    line-height: 27px;
    color: #fff
}

.offerDetails .content .popContainer .buttonContainer:hover {
    transform: scale(0.95);

}



@keyframes fromBottomContainer {
    from {
        height: 0%;
    }

    to {
        height: 60%
    }
}


@media screen and (max-width: 900px) {
    .offerDetails {
        width: 100% !important;
        margin-right: 0;
        margin-left: 0;
    }
}