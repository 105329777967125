.thankyouClass {
    position: relative;
    background-color:white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    /* width: 35%; */
}
@media screen and (max-width: 900px) {
    .thankyouClass {
        width: 100% !important;
    }
}
.thankyouClass .thankyouClassContainer {
    position: relative;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;
    margin: 17px;
    width: 90%;
}
.thankyouClass .thankyouClassContainer .checkimg{
    position: relative;    
}

.thankyouClass .thankyouClassContainer .holatext{
    position: relative;
    font-family: 'poppins-bold','poppins-bold-ar';
    font-size: 32px;
    color: rgba(30, 61, 88, 1);
    text-align: center;
    margin-top: 24px;
    height: 45px;
}
.thankyouClass .thankyouClassContainer .yourordertext{
    position: relative;
    font-family: 'poppins-bold','poppins-bold-ar';
    font-size: 22px;
    color: rgba(30, 61, 88, 1);
    text-align: center;
}
.thankyouClass .thankyouClassContainer .trackorderbutton{
    position: relative;
    margin-top: 38px;
    width: 100%;
    background-color: var(--themeColor);
    border-radius: 6px;
    box-shadow: 0px 0px 9px 0px rgba(158, 158, 158, 0.5);
    text-align: center;
    color: white;
    padding: 16px;
    font-family: 'poppins-bold','poppins-bold-ar';
    font-size: 14px;
    transition: all ease-in 200ms;
}
.thankyouClass .thankyouClassContainer .trackorderbutton:hover{
    transform: scale(0.95);
}
