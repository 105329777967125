.getapp {
    /* display: flex; */
    justify-content: flex-end !important;
    align-items: baseline !important;
    padding: 0 !important;
    /* background-color:rgba(0,0,0,0) !important; */
    width: 35% !important;
    right: 0 !important;
}
.getapp .content{
    width: 100%;

}
.getapp .step1Container {
    background-color: var(--themeColor);
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

}

.getapp .step1Container .infoContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 17px 18px 17px 18px;
}

.getapp .step1Container .infoContainer .priceContainer {
    width: 78px;
    height: 78px;
    padding: 20px;
    background-image: url('../img/getAppGift.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.getapp .step1Container .infoContainer .priceContainer .price {
    margin-top: 4px;
    font-size: 34px;
    font-family: 'poppins-bold', 'poppins-bold-ar';
    line-height: 25px;
}

.getapp .step1Container .infoContainer .priceContainer .currency {
    font-size: 14px;
    font-family: 'poppins-semiBold', 'poppins-bold-ar';
}

.getapp .step1Container .infoContainer .descriptionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 12px;
}

.getapp .step1Container .infoContainer .descriptionContainer .descriptionText {
    font-size: 14px;
    font-family: 'poppins-medium', 'poppins-medium-ar';
    text-align: start;
    line-height: 21px;
}

.getapp .step1Container .infoContainer .descriptionContainer .getAppButton {
    font-size: 14px;
    font-family: 'poppins-medium', 'poppins-medium-ar';
    text-align: start;
    line-height: 21px;
    background-color: #00000022;
    margin-top: 6px;
    padding: 6px 12px;
    border-radius: 6px;
    cursor: pointer;
}

.getapp {
    /* display: flex; */
    justify-content: flex-end !important;
    align-items: baseline !important;
    padding: 0 !important;
    /* background-color:rgba(0,0,0,0) !important; */
    width: 35% !important;
    right: 0 !important;
}

.getapp .step2Container {
    background-color: #fff;
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

}

.getapp .step2Container .infoContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 28px 18px 88px 18px;
    color: #343434;
    text-align: start;
}

.getapp .step2Container .infoContainer .title {
    font-size: 18px;
    font-family: 'poppins-bold', 'poppins-bold-ar';
    text-align: start;
    line-height: 23px;
}

.getapp .step2Container .infoContainer .desc {
    font-size: 14px;
    font-family: 'poppins-medium', 'poppins-medium-ar';
    text-align: start;
    line-height: 23px;
    color: #6D7278;
}

.getapp .step2Container .infoContainer .stepCont {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.getapp .step2Container .infoContainer .stepCont img {
    border: 1px dashed var(--themeColor);
    border-radius: 8px;
    height: 30px;
    width: 30px;
    padding: 6px;
}

.getapp .step2Container .infoContainer .stepCont .stepContTitle {
    font-size: 14px;
    font-family: 'poppins-medium', 'poppins-medium-ar';
    text-align: start;
    line-height: 18px;
    margin: 0 13px;

}

.getapp .step2Container .infoContainer .stepCont .stepContTitle span {
    font-size: 14px;
    font-family: 'poppins-bold', 'poppins-bold-ar';
    text-align: start;
    line-height: 18px;
}

.getapp .step2Container .infoContainer .stepContTitle {
    font-size: 14px;
    font-family: 'poppins-medium', 'poppins-medium-ar';
    text-align: start;
    line-height: 18px;
    margin: 0 13px;

}

.getapp .step2Container .infoContainer .stepContTitle span {
    font-size: 14px;
    font-family: 'poppins-bold', 'poppins-bold-ar';
    text-align: start;
    line-height: 18px;
}
@media screen and (max-width: 900px) {
    .getapp {
        width: 100% !important;
    }
}