.itemClass {
    justify-content: flex-end !important;
    align-items: baseline !important;
    padding: 0 !important;
    background-color:rgba(0,0,0,0) !important;
    /* width: 35% !important; */
    right: 0 !important;

}

.itemClass  {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: rgba(0, 0, 0, .85);
    /* overflow: auto; */
}


.itemClass .popContainer {
    background-color: white;
    width: 100%;
    min-width: 100% !important;
    border-radius: 16px 16px 0 0;
    margin: 0;
}


.itemClass .popContainer .titleContainers {
    /* justify-content: center; */
    position: relative;
    width: 100%;
    background-color: white;
    padding: 16px 16px 0;
    
}
.itemClass .itemImage{
    width: 100%;
    height: 35vh;
    background-size: cover;
    background-position:center;

}
.itemClass .popContainer .titleContainers .title {
    display: flex;
    position: relative;
    width: auto;
    font-family: 'poppins-bold','poppins-bold-ar'; 
    font-size: 22px;
    padding: 0 0 8px;
    color: rgba(74, 74, 74, 1);
    line-height: 22px;
}
.itemClass .popContainer .titleContainers .details {
    font-family: 'poppins-regular','poppins-medium-ar';
    font-size: 14px;
    /* line-height: 18px; */
    color: #4a4a4a;
    cursor: default;
    display: flex;

}
.itemClass .underline{
    position: relative;
    /* margin-top: 8px; */
    height: 6px;
    width: 100%;
    background-color: var(--themeColor);
    opacity: 0.05;
}
.itemClass .popContainer .inputsContainer{
    margin: 16px 0 120px;
    padding: 0;
}
.itemClass .popContainer .inputsContainer .deleteItemsContainer{
   position: relative;
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-top: 21px;
}
.itemClass .popContainer .inputsContainer .deleteItemsContainer .textContainer{
  position: relative;
 }
 .itemClass .popContainer .inputsContainer .deleteItemsContainer .textContainer .upperText{
    position: relative;
    font-family: 'poppins-medium','poppins-medium-ar'; 
    font-size: 16px;
    text-align: start;
    color: rgba(74, 74, 74, 1);
 }
 .itemClass .popContainer .inputsContainer .deleteItemsContainer .textContainer .lowerText{
    position: relative;
    font-family: 'poppins-medium','poppins-medium-ar'; 
    text-align: start;
    margin-top: 3px;
    padding: 4px;
    font-size: 10px;
    color: rgba(184, 184, 184, 1);
}
 .itemClass .popContainer .inputsContainer .deleteItemsContainer .btnContainer{
    width: 20%;
 }
.itemClass .popContainer .inputsContainer .mainoption{
    padding: 16px;
}

.itemClass .popContainer .inputsContainer .mainoption .title{
    text-transform: capitalize;
    font-family: 'poppins-medium','poppins-medium-ar'; 
    font-size: 16px;
    font-weight: bold;
    color: rgba(74, 74, 74, 1);
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.itemClass .popContainer .inputsContainer .mainoption .subtitle{
    width: fit-content;
    margin-top: 3px;
    font-family: 'poppins-regular','poppins-regular-ar'; 
    font-size: 12px;
    color: rgba(184, 184, 184, 1)
}
.itemClass .popContainer .inputsContainer .mainoption .title span{
    font-size: 0.75rem;
    padding: 0 4px;
    color: #ff7774;
    font-family: 'poppins-medium','poppins-medium-ar';

}

.itemClass .popContainer .inputsContainer .mainoption .checkitem .price{
    font-family: 'poppins-medium','poppins-medium-ar'; 
    font-size: 14px;
    color: rgba(74, 74, 74, 1);
    min-width: 60px;
}
.itemClass .popContainer .inputsContainer .mainoption .floatright .ui.checkbox label {
    padding-left: 0;
    padding-right: 1.5em;
}

.itemClass .popContainer .inputsContainer .mainoption .floatright .ui.checkbox input,
.itemClass .popContainer .inputsContainer .mainoption .floatright .ui.checkbox label:before {
    left: auto;
    right: 0;
}

.itemClass .popContainer .inputsContainer .mainoption .floatright .ui.checkbox label:after {
    left: auto;
    right: 0;
    /* transition:background 0.3s ease 0s, right 0.3s ease 0s; */
}

.itemClass .popContainer .inputsContainer .mainoption .floatright .ui.checkbox input:checked+label:after {
    left: auto;
    right: 0;
    /* transition:background 0.3s ease 0s, right 0.3s ease 0s; */
}
.itemClass .popContainer .inputsContainer .mainoption .itemNotes input{
    border: none;
    border-bottom: 1px solid black;
    border-color: rgba(234, 234, 234, 1) !important;
    padding: 8px 0 !important;
    color: rgba(74, 74, 74, 1) !important;
    font-family: 'poppins-light','poppins-light-ar';

}

.ui.radio.checkbox .box:after, .ui.radio.checkbox label:after {
    background-color: var(--themeColor) !important;
    top:2px;
}
.ui.checkbox .box:before, .ui.checkbox label:before{
    top:2px !important;

}
.ui.radio.checkbox .box, .ui.radio.checkbox label {
    font-family: 'poppins-medium','poppins-medium-ar'; 
    font-size: 14px;
    color: rgba(74, 74, 74, 1);
    /* line-height: 20px; */
    padding: 0 24px;
}
.itemClass .popContainer .inputsContainer .qtybuttoncontaitner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px 0 60px;
}
.itemClass .popContainer .inputsContainer .qtybuttoncontaitner .qtybutton {
    position: relative;
    border: 1px solid var(--themeColor);
    border-radius: 20px;
    display: flex;
    height: 40px;
    width: 150px;
}
.itemClass .popContainer .inputsContainer .qtybuttoncontaitner .qtybutton .add {
    position: relative;
    font-size: 22px;
    font-family: 'poppins-bold','poppins-bold-ar'; 
    font-weight: bold;
    color: var(--themeColor);
    text-align: center;
    cursor: pointer;
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.itemClass .popContainer .inputsContainer .qtybuttoncontaitner .qtybutton .text{
    position: relative;
    font-family: 'poppins-regular','poppins-regular-ar'; 
    font-size: 12px;
    font-weight: 500;
    color: var(--themeColor);
    text-align: center;
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.itemClass .popContainer .inputsContainer .qtybutton .remove{
    position: relative;
    font-family: 'poppins-bold','poppins-bold-ar'; 
    font-size: 22px;
    font-weight: bold;
    color: var(--themeColor);
    text-align: center;
    cursor: pointer;
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.itemClass .popContainer .submitbuttoncontainer{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 24px;
    margin-left: 35%;
    margin-right: 35%;
    height: 10%;
    /* background: white; */
    width: 30%;
    z-index: 999;
    left: 0;
}
.itemClass .popContainer .submitbuttoncontainer .submitbutton{
    position: relative;
    width: 100%;
    background-color: var(--themeColor);
    border-radius: 6px;
    box-shadow: 0px 0px 9px 0px rgba(158, 158, 158, 0.5);
    cursor: pointer;
    color: white;
    padding: 16px;
    font-family: 'poppins-bold','poppins-bold-ar'; 
    font-size: 14px;
    height: 60px;
    transition: all 100ms;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}
.itemClass .popContainer .submitbuttoncontainer .submitbutton:hover{
   transform: scale(0.95);
    
}
.itemClass .popContainer .submitbuttoncontainer .submitbutton .addtobasket{
    position: relative;
    font-family: 'poppins-light','poppins-light-ar'; 
    font-size: 18px;
    font-weight: 300;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 2px;
}
.itemClass .popContainer .submitbuttoncontainer .submitbutton .totalprice{
    position: relative;
    font-family: 'poppins-bold','poppins-bold-ar'; 
    font-size: 14px;
    margin-top: 3px;
    color: rgba(255, 255, 255, 1);
    
}
@media screen and (max-width: 900px) {
    .itemClass  {
        width: 100% !important;
    }
    .itemClass .popContainer .submitbuttoncontainer {
        width: 100%;
        margin-left: 0;
        margin-right:0;

    }
}