.extrasClass {
    justify-content: flex-end !important;
    align-items: baseline !important;
    padding: 0 !important;
    background-color:rgba(0,0,0,0) !important;
    width: 35% !important;
    right: 0 !important;

}

.extrasClass .content {
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: rgba(0, 0, 0, .85);
}
@media screen and (max-width: 900px) {
    .extrasClass  {
        width: 100% !important;
    }
}

.extrasClass .content .popContainer {
    background-color: white;
    width: 100%;
    height: 90%;
    min-width: 100% !important;
    border-radius: 16px 16px 0 0;
    margin: 0;
    overflow: hidden;
}

/* .extrasClass .content .popContainer .iconTimes {
    color: rgba(213, 213, 213, 1);
    position: absolute;
    right: 0;
    padding: 24px;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
    z-index: 1;
} */

.extrasClass .content .popContainer .titleContainers {
    justify-content: center;
    position: relative;
    width: 100%;
    background-color: white;
    padding-top: 15px;
    height: 11%;

    
}
.extrasClass .content .popContainer .titleContainers .title {
    display: flex;
    justify-content: center;
    position: relative;
    height: 29px;
    width: auto;
    font-family: 'poppins-bold','poppins-bold-ar'; 
    font-size: 22px;
    color: rgba(74, 74, 74, 1);
}
.extrasClass .content .popContainer .titleContainers .underline{
    position: relative;
    margin-top: 8px;
    height: 1px;
    width: 100%;
    background-color: rgba(213, 213, 213, 1);
}
.extrasClass .content .popContainer .inputsContainer{
    overflow: auto;
    margin: 22px 0;
    height: 100%;
}
.extrasClass .content .popContainer .inputsContainer .deleteItemsContainer{
   position: relative;
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin: 21px;
  padding-bottom: 8px;
   border-bottom: 1px solid rgba(204, 204, 204, 0.25);
}
.extrasClass .content .popContainer .inputsContainer .deleteItemsContainer .textContainer{
  position: relative;
 }
 .extrasClass .content .popContainer .inputsContainer .deleteItemsContainer .textContainer .upperText{
    position: relative;
    font-family: 'poppins-medium','poppins-medium-ar'; 
    font-size: 16px;
    text-align: start;
    color: rgba(74, 74, 74, 1);
 }
 .extrasClass .content .popContainer .inputsContainer .deleteItemsContainer .textContainer .lowerText{
    position: relative;
    font-family: 'poppins-medium','poppins-medium-ar'; 
    text-align: start;
    padding: 3px;
    font-size: 10px;
    color: rgba(184, 184, 184, 1);
}
 .extrasClass .content .popContainer .inputsContainer .deleteItemsContainer .btnContainer{
    width: 20%;
 }
.extrasClass .content .popContainer .inputsContainer .mainoption{
    padding: 0 16px 16px;
}

.extrasClass .content .popContainer .inputsContainer .mainoption .title{
    text-transform: capitalize;
    font-family: 'poppins-regular','poppins-regular-ar'; 
    font-size: 16px;
    font-weight: bold;
    color: rgba(74, 74, 74, 1);
}
.extrasClass .content .popContainer .inputsContainer .mainoption .subtitle{
    width: fit-content;
    margin-top: 3px;
    font-family: 'poppins-medium','poppins-medium-ar'; 
    font-size: 12px;
    color: rgba(184, 184, 184, 1)
}
.extrasClass .content .popContainer .inputsContainer .mainoption .subtitle span{
    color: #ff7774
}

.extrasClass .content .popContainer .inputsContainer .mainoption .checkitem .price{
    font-family: 'poppins-regular','poppins-regular-ar'; 
    font-size: 14px;
    font-weight: 500;
    color: rgba(74, 74, 74, 1);
}
.extrasClass .content .popContainer .inputsContainer .mainoption .floatright .ui.checkbox label {
    padding-left: 0;
    padding-right: 1.5em;
}

.extrasClass .content .popContainer .inputsContainer .mainoption .floatright .ui.checkbox input,
.extrasClass .content .popContainer .inputsContainer .mainoption .floatright .ui.checkbox label:before {
    left: auto;
    right: 0;
}

.extrasClass .content .popContainer .inputsContainer .mainoption .floatright .ui.checkbox label:after {
    left: auto;
    right: 0;
    /* transition:background 0.3s ease 0s, right 0.3s ease 0s; */
}

.extrasClass .content .popContainer .inputsContainer .mainoption .floatright .ui.checkbox input:checked+label:after {
    left: auto;
    right: 0;
    /* transition:background 0.3s ease 0s, right 0.3s ease 0s; */
}

.ui.radio.checkbox .box:after, .ui.radio.checkbox label:after {
    background-color: var(--themeColor) !important;
}
.ui.radio.checkbox .box, .ui.radio.checkbox label {
    font-family: 'poppins-medium','poppins-medium-ar'; 
    font-size: 16px;
    color: rgba(74, 74, 74, 1);
}
.extrasClass .content .popContainer .inputsContainer .qtybuttoncontaitner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
}
.extrasClass .content .popContainer .inputsContainer .qtybuttoncontaitner .qtybutton {
    position: relative;
    border: 1px solid var(--themeColor);
    border-radius: 20px;
    display: flex;
    height: 40px;
    width: 120px;
}
.extrasClass .content .popContainer .inputsContainer .qtybuttoncontaitner .qtybutton .add {
    position: relative;
    font-size: 12px;
    font-family: 'poppins-regular','poppins-regular-ar'; 
    font-weight: bold;
    color: var(--themeColor);
    text-align: center;
    cursor: pointer;
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.extrasClass .content .popContainer .inputsContainer .qtybuttoncontaitner .qtybutton .text{
    position: relative;
    font-family: 'poppins-regular','poppins-regular-ar'; 
    font-size: 12px;
    font-weight: 500;
    color: var(--themeColor);
    text-align: center;
    width: 40%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.extrasClass .content .popContainer .inputsContainer .qtybutton .remove{
    position: relative;
    font-family: 'poppins-regular','poppins-regular-ar'; 
    font-size: 12px;
    font-weight: bold;
    color: var(--themeColor);
    text-align: center;
    cursor: pointer;
    width: 30%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.extrasClass .content .popContainer .submitbuttoncontainer{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 24px;
    height: 10%;
    background: white;
}
.extrasClass .content .popContainer .submitbuttoncontainer .submitbutton{
    position: relative;
    width: 80%;
    background-color: var(--themeColor);
    border-radius: 6px;
    box-shadow: 0px 0px 9px 0px rgba(158, 158, 158, 0.5);
    cursor: pointer;
    color: white;
    padding: 16px;
    font-family: 'poppins-bold','poppins-bold-ar'; 
    font-size: 14px;
    height: 60px;
    transition: all 100ms;
    
}
.extrasClass .content .popContainer .submitbuttoncontainer .submitbutton:hover{
   transform: scale(0.95);
    
}
.extrasClass .content .popContainer .submitbuttoncontainer .submitbutton .addtobasket{
    position: relative;
    font-family: 'poppins-light','poppins-light-ar'; 
    font-size: 18px;
    font-weight: 300;
    color: rgba(255, 255, 255, 1);
    text-align: center;
    text-transform: uppercase;
}
.extrasClass .content .popContainer .submitbuttoncontainer .submitbutton .totalprice{
    position: relative;
    font-family: 'poppins-bold','poppins-bold-ar'; 
    font-size: 14px;
    margin-top: 3px;
    color: rgba(255, 255, 255, 1);
}