.btnContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid var(--counterBtn);
    border-radius: 6px;
    background-color: var(--counterBtn);
    text-align: center;
    overflow: hidden;
    font-size: 14px;
    height: 28px;
    width: 100%;
}

.btnContainerInv {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid var(--themeColor);
    border-radius: 8px;
    text-align: center;
    overflow: hidden;
    height: 24px;
    width: 100px;
    font-size: 14px;
    cursor: pointer;
}

.btnContainer .decrease, .btnContainer .increase {
    /* width: 30%; */
    min-width: 30%;
    cursor: pointer;
    color: var(--counterBtnColor);
    font-family: 'poppins-bold','poppins-bold-ar';
    display: grid;
    align-items: center;
}

.btnContainerInv .decreaseInv, .btnContainerInv .increaseInv {
    background-color: none;
    /* width: 30%; */
    min-width: 30%;
    cursor: pointer;
    font-family: 'poppins-bold','poppins-bold-ar';
    color: var(--themeColor);
    display: grid;
    align-items: center;
}

.btnContainer .counter {
    width: 40%;
    background-color: white;
    color: var(--secondaryColor);
    font-family: 'poppins-bold','poppins-bold-ar';
    font-size: 12px;
    height: 100%;
    display: grid;
    align-items: center;
}

.btnContainerInv .counterInv {
    width: 40%;
    background-color: var(--themeColor);
    color: white;
    font-family: 'poppins-bold','poppins-bold-ar';
    font-size: 10px;
    display: grid;
    align-items: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}