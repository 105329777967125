.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.emptySpace {
  -webkit-filter: blur(3rem);
  height: 100%;
  left: -10%;
  position: absolute;
  top: -10%;
  width: 120%;
}

.emptySpace .sideImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;

}

.leftSide {
  width: 35%;
  min-height: 100vh;
  width: 30%;
  margin-left: 35%;
  margin-right: 35%;

  z-index: 6;
  background-color: #fff;
  position: relative;
  /* height: 100vh; */
}

@media screen and (max-width: 900px) {
  .leftSide {
    width: 100%;
    margin-left: 0;
    margin-right: 0;

  }

  .emptySpace {
    display: none;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}