/* @font-face {
  font-family: 'tfBlackItalic';
    src: url(./fontFamily/Futura-Heavy-Italic-font.ttf);
} */

@font-face {
  font-family: 'tfBlack';
  src: url(./fontFamily/Futura-Heavy-font.ttf);
}

@font-face {
  font-family: 'tfBold';
  src: url(./fontFamily/Futura-Bold-font.ttf);
}

/* @font-face {
  font-family: 'tfBoldItalic';
  src: url(./fontFamily/Futura-Bold-Italic-font.ttf);
} */

@font-face {
  font-family: 'tfLight';
  src: url(./fontFamily/Futura-Light-font.ttf);
}

/* @font-face {
  font-family: 'tfLightItalic';
  src: url(./fontFamily/Futura-Light-Italic-font.ttf);
} */

@font-face {
  font-family: 'tfMedium';
  src: url(./fontFamily/futura-medium-bt.ttf);
}

/* @font-face {
  font-family: 'tfMediumItalic';
  src: url(./fontFamily/Futura-Medium-Italic-font.ttf);
} */

@font-face {
  font-family: 'tfRegular';
  src: url(./fontFamily/futura-medium-bt.ttf);
}

/* @font-face {
  font-family: 'tfRegularItalic';
  src: url(./fontFamily/Futura-Medium-Italic-font.ttf);
} */

@font-face {
  font-family: 'tfThin';
  src: url(./fontFamily/Futura-Light-font.ttf);
}

/* @font-face {
  font-family: 'tfThinItalic';
  src: url(./fontFamily/Futura-Light-Italic-font.ttf);
} */

/* @font-face {
  font-family: 'tfcondensed';
  src: url(./fontFamily/futura-medium-condensed-bt.ttf);
} */

@font-face {
  font-family: 'poppins-light-ar';
  src: url(./fontFamily/Tajawal-Light.ttf);
}

@font-face {
  font-family: 'poppins-medium-ar';
  src: url(./fontFamily/Tajawal-Medium.ttf);
}

@font-face {
  font-family: 'poppins-regular-ar';
  src: url(./fontFamily/Tajawal-Regular.ttf);
}

@font-face {
  font-family: 'poppins-bold-ar';
  src: url(./fontFamily/Tajawal-Bold.ttf);
}

@font-face {
  font-family: 'poppins-regular';
  src: url(./fontFamily/Poppins-Regular.ttf);
}

@font-face {
  font-family: 'poppins-medium';
  src: url(./fontFamily/Poppins-Medium.ttf);
}

@font-face {
  font-family: 'poppins-light';
  src: url(./fontFamily/Poppins-Light.ttf);
}

@font-face {
  font-family: 'poppins-bold';
  src: url(./fontFamily/Poppins-Bold.ttf);
}

@font-face {
  font-family: 'poppins-semiBold';
  src: url(./fontFamily/Poppins-SemiBold.ttf);
}

:root {
  --themeColor: rgba(188, 148, 61, 1);
  --themeColorOpacity30: rgba(188, 148, 61, 0.3);
  --themeColorOpacity15: rgba(188, 148, 61, 0.15);
  --themeColorOpacity10: rgba(188, 148, 61, 0.1);
  --secondaryColor: #9f3038;
  --counterBtn: rgba(188, 148, 61, 0.3);
  --counterBtnColor: rgba(188, 148, 61);
}

body {
  margin: 0;
  /* font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif,'tfLight-ar' !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'poppins-regular', 'poppins-regular-ar' !important;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.ui.labeled.input:not([class*="corner labeled"]) .label:first-child {
  font-family: 'poppins-regular', 'poppins-regular-ar' !important;
}

code {
  font-family: 'tfRegular';
}