 .header {
     /* display: flex;
     text-align: center;
     justify-content: space-between; */
     background-color: white;
     /* height: 60px; */
     /* padding: 10px 8px; */
     position: relative;
     /* top: 0; */
     z-index: 999;
     /* width: 35%; */
     -webkit-box-shadow: 0 1px 7px rgba(0, 0, 0, .07);
     box-shadow: 0 1px 7px rgba(0, 0, 0, .07);
 }

 .header .selectLocation {
     width: 100%;
     align-self: flex-end;
     justify-content: flex-start;
     display: flex;
     flex-direction: column;
     position: relative;
     cursor: pointer;
     align-items: flex-start;
     padding: 16px 14px 0;
 }

 .header .selectLocation .resImage {
     height: 60px;
     width: 60px;
     border-radius: 13px;
     box-shadow: 0px 0px 4px 0px rgba(199, 199, 199, 0.5);
     background-size: contain;
 }

 .header .selectLocation .resTitle {
     font-size: 22px;
     font-weight: bold;
     color: rgba(74, 74, 74, 1);
     font-family: 'poppins-bold', 'poppins-bold-ar';
     padding: 0 12px;
     text-align: start;
     line-height: 28px;
 }

 .header .selectLocation .tagView {
     display: flex;
     align-items: center;
     padding: 0 0 12px;
 }

 .header .selectLocation .tagView .tag {
     display: flex;
     align-items: center;
     /* padding:0 8px; */
 }

 .header .codesView {
     display: flex;
     align-items: inherit;
     overflow: auto;
 }


 .header .codesView .loyaltyContainer {
     margin: 16px;
     display: flex;
     align-items: center;
     justify-content: space-between;
     padding: 10px 16px;
     background-color: rgba(255, 255, 255, 1);
     border-radius: 25px;
     box-shadow: 0px 0px 16px 0px rgba(163, 107, 32, 0.1);
     font-size: 12px;

 }

 .discountIcon {
     height: 25px;
     width: 25px;
     margin: 0 8px;
     -webkit-mask-size: cover;
     mask-size: cover;
     -webkit-mask-image: url('../MenuComponents/img/discountIcon.png');
     mask-image: url('../MenuComponents/img/discountIcon.png');
     background-color: var(--themeColor);

 }

 .header .codesView .loyaltyContainer .action {
     margin: 0;
     min-height: auto;
     background-color: white !important;
     box-shadow: none !important;
     font-family: 'poppins-regular', 'poppins-regular-ar';
     padding: 4px;
 }

 .header .codesView .loyaltyContainer .pts {
     color: rgba(74, 74, 74, 1);
     font-size: 16px;
     font-family: 'poppins-bold', 'poppins-bold-ar';
     padding: 0;

 }

 .header .selectLocationView {
     /* padding: 16px ; */
     width: 100%;
     /* background-color: var(--counterBtn); */
     /* display: flex; */
     /* align-self: center; */
     /* justify-content: space-between; */
     /* margin-bottom: 16px; */
 }

 .header .selectLocation .tagView .tag .icon, .header .selectLocationView .icon {
     color: var(--themeColor);
     margin: 0;
     padding: 0;
     display: flex;
     justify-content: center;
     align-items: center;

 }

 .header .selectLocation .tagView .tag .text {
     font-size: 12px;
     color: rgba(74, 74, 74, 1);
     padding: 0 2px;

 }

 .header .selectLocation .deliveryType, .header .selectLocationView .deliveryType {
     margin: 0 8px;
     position: relative;
     font-family: 'poppins-regular', 'poppins-regular-ar';
     font-size: 12px;
     color: var(--themeColor);
 }

 .header .selectLocation .textContainer {
     margin: 0 4px;
     position: relative;
     font-size: 14px;
     color: rgba(255, 255, 255, 1);
     align-items: center;
     justify-content: flex-start;
     display: flex;
 }

 .header .selectLocation .textContainer .icon {
     position: relative;
     color: var(--themeColor);
     font-size: 18px;
     margin: 0 !important;
 }

 .header .selectLocation .textContainer .location, .header .selectLocationView .location {
     position: relative;
     font-size: 12px;
     color: rgba(255, 255, 255, 1);
     font-family: 'poppins-regular', 'poppins-regular-ar';
     color: rgba(74, 74, 74, 1);
 }

 .header .titleBar {
     font-size: 18px;
     /* font-weight: bolder; */
     font-family: 'poppins-bold', 'poppins-bold-ar';

     color: rgba(11, 23, 33, 1);
     text-align: center;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     width: 60%;
     padding: 2px;
     min-height: 30px;

     /* letter-spacing: 0.8px; */
 }

 .header .back {
     width: 5%;
     align-self: center;
     cursor: pointer;
     height: 24px;
     object-fit: contain;
     object-position: 0% 50%;
     color: var(--themeColor);
     font-size: 28px;
     position: relative;
     animation: showBack 300ms ease-in forwards;
 }

 @keyframes showBack {
     from {
         top: -30px;
         opacity: 0.1;
     }

     to {
         top: 0;
         opacity: 1
     }
 }

 .header .logoContainer {
     width: 40%;
     display: flex;
     justify-content: center;
     align-items: center;
 }

 .header .logo {
     align-self: center;
     height: 80%;
     object-fit: contain;
 }

 .header .cart {
     width: 50%;
     align-self: flex-end;
     align-items: center;
     display: flex;
     color: var(--themeColor) !important;
     /* justify-content: flex-end; */
     background-color: transparent;
 }

 .ui.loading.button.cart {
     border: none !important;

     width: auto !important;
 }

 .header .cart .totalCost {
     padding: 0 10px;
     background-color: var(--secondaryColor);
     color: white;
     height: 25px;
     font-weight: 500;
     border-radius: 50%;
     font-size: 10px;
     width: 25px;
     display: flex;
     justify-content: center;
     align-items: center;
 }

 @media screen and (max-width: 900px) {
     .header {
         width: 100%;
     }
 }