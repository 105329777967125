.notif{
    width: calc(35% - 16px);
    position: fixed;
    top: 16px;
    margin: 16px 8px 0;
    background-color: #fff;
    z-index: 99999;
    transition: all 1s ease;
    animation: notiff 3s 2 alternate ease forwards;
    -moz-animation: notiff 3s 2 alternate ease forwards;
    -webkit-animation: notiff 3s 2 alternate ease forwards;
    font-size: 16px;
    /* box-shadow: 0px 14px 20px 0px rgba(199, 199, 199, 0.9); */
    margin: 0 8px;
    border-radius: 4px;

}
.notif .notif_title{
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 8px;

}
@keyframes notiff {
    0%  {top: -100px}
    10%  {top:16px}
    100% {top:16px}
  }

  @media screen and (max-width: 900px) {
    .notif{
        width:  calc(100% - 16px) ;
    }
}