.loyalty {
    justify-content: flex-end !important;
    align-items: baseline !important;
    padding: 0 !important;
    /* background-color:rgba(0,0,0,0) !important; */
    width: 35% !important;
    right: 0!important ;
}

.loyalty .content {
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: flex-end;
    /* background-color: rgba(0, 0, 0, .85); */
}
.loyalty .content .popContainer {
    background-color: transparent;
    background-image: linear-gradient(to bottom ,transparent,#fff,#fff);
    width: 100%;
    height: auto;
    min-width: 100% !important;
    border-radius: 16px 16px 0 0 ;
    animation: fromBottomContainer 300ms ease-in;
    -moz-animation: fromBottomContainer 300ms ease-in;
    -webkit-animation: fromBottomContainer 300ms ease-in;
    color: rgba(74, 74, 74, 1);
    /* padding: 24px; */
    margin: 0;
    overflow: auto;
    max-height: 99vh;
}
.loyalty .content .popContainer .resLogo{
    position: relative;
    height: 100px;
    width: 100px;
    border-radius: 8px;
    box-shadow: 0px 0px 4px 0px rgba(199, 199, 199, 0.5);
}
.loyalty .content .popContainer .loyaltyHeader{
    font-size: 22px;
    /* text-align: initial; */
    font-family: 'poppins-medium','poppins-medium-ar';
    padding: 24px 0 4px;
    /* display: flex; */
    direction: inherit;
}
.bolded{
    font-family: 'poppins-bold','poppins-bold-ar' !important;
    direction: inherit;
}
.loyalty .content .popContainer .loyaltyContent{
    font-size: 14px;
    font-family: 'poppins-regular','poppins-regular-ar';
    /* text-align: initial; */
    padding: 0 8px;
    line-height: 18px;

}
.slick-arrow{
    display: none !important;
}
@keyframes fromBottomContainer {
    from {height: 0%;}
    to {height: 60%}
  }


  @media screen and (max-width: 900px) {
    .loyalty {
        width: 100% !important;
    }
}